import { rentalServicesData } from "../../assets/common";
import { ServiceCard } from "../../components";
import { Fade } from "react-reveal";

export const RentingServicePageGrid = () => {
  return (
    <>
      <section className="renting-service-page-grid-container">
        <div className="renting-service-page-grid-wrapper">
          <div className="grid-detail-wrapper">
            <Fade bottom>
              <h4 className="title">
                We don’t just build homes, we develop Iconic Homes.
              </h4>
            </Fade>
            <Fade bottom>
              <p className="description">
                We understand that luxury homes requires a heavy budget to
                afford, but we have different approach. We develop best-in-class
                communities with affordable prices and convenient service and
                maintenance experience. Through our comprehensive automated
                online platform “SUNLAND Communities Mobile App” you can
                communicate with us, book the required service, pay you rent,
                get notifications about activities in the communities, and stay
                up-to-date with everything happening around you
              </p>
            </Fade>
          </div>
          <div className="cards-grid-wrapper">
            {rentalServicesData.map((card, index) => {
              return (
                <div key={index}>
                  <Fade bottom>
                    <ServiceCard service={card} />
                  </Fade>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
