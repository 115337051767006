import {
  AddressIcon,
  AirConditioning,
  Balcony,
  Bathroom,
  BathroomImage,
  Bedroom,
  BedroomImage,
  Ceiling,
  Door,
  DreamToReality,
  Electrical,
  Elevator,
  EmailIcon,
  Facebook,
  Floor,
  FocusForResult,
  Garden,
  GardenImage,
  Instagram,
  Kitchen,
  KitchenImage,
  Lift,
  LinkedIn,
  Living,
  Others,
  Parking,
  PhoneIcon,
  Playground,
  Plumbing,
  RentingServiceCardImage,
  RoofImage,
  SeaView,
  SeaViewImage,
  SwimmingPool,
  SwimmingPoolImage,
  Twitter,
  WaterHeater,
  Window,
} from "..";

export const rentalServicesData = [
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Home Hunt",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },

  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "CSC Reality",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Manglam",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },

  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "CSC Reality",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Manglam",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Home Hunt",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },

  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "CSC Reality",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Manglam",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "Bedroom",
        icon: <Bedroom />,
      },
      {
        name: "Garden",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Parking",
        icon: <Parking />,
      },
      {
        name: "Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
  {
    img: [
      {
        original: KitchenImage,
        thumbnail: KitchenImage,
      },
      {
        original: BathroomImage,
        thumbnail: BathroomImage,
      },
      {
        original: BedroomImage,
        thumbnail: BedroomImage,
      },
      {
        original: GardenImage,
        thumbnail: GardenImage,
      },
      {
        original: RoofImage,
        thumbnail: RoofImage,
      },
      {
        original: SeaViewImage,
        thumbnail: SeaViewImage,
      },
      {
        original: SwimmingPoolImage,
        thumbnail: SwimmingPoolImage,
      },
    ],
    tag: "Home Hunt",
    location: "Abu Dhabi, Rabdan Area",
    propertyName: "Rabdan Residential Complex",
    bhk: "2",
    amenities: [
      {
        name: "Lift",
        icon: <Lift />,
      },
      {
        name: "Balcony",
        icon: <Balcony />,
      },
      {
        name: "2 Full Bathrooms",
        icon: <Bathroom />,
      },
      {
        name: "Living",
        icon: <Living />,
      },
      {
        name: "2 Bedrooms",
        icon: <Bedroom />,
      },
      {
        name: "Shared Garden with Neighbors",
        icon: <Garden />,
      },
      {
        name: "Kitchen",
        icon: <Kitchen />,
      },
      {
        name: "Shaded Car Parking",
        icon: <Parking />,
      },
      {
        name: "Kids Playground",
        icon: <Playground />,
      },
      {
        name: "Sea View",
        icon: <SeaView />,
      },
      {
        name: "Shared Swimming Pool",
        icon: <SwimmingPool />,
      },
    ],
    contactNumber: "+911231231231",
  },
];

export const socialHandlesData = [
  {
    name: "Facebook",
    url: "http://www.facebook.com",
    icon: <Facebook />,
  },
  {
    name: "Twitter",
    url: "http://twitter.com",
    icon: <Twitter />,
  },
  {
    name: "Instagram",
    url: "http://instagram.com",
    icon: <Instagram />,
  },
  {
    name: "LinkedIn",
    url: "http://www.linkedin.com",
    icon: <LinkedIn />,
  },
];

export const navItems = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Renting",
    path: "/renting-services",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
];

export const qualityData = [
  {
    title: "Abu Dhabi - The Ultimate Destination For You",
    description:
      "Abu Dhabi is ranked as one of the Safest Cities to live in the world. It’s economic and political importance is significant. The UAE is home of the world’s largest corporations, and the UAE is working to diversify its economy to be home of the most brilliant minds and most advanced technologies. The ease of doing business in the UAE is unparalleled and the residential and commercial projects are with incredible luxury standards.",
    image: FocusForResult,
  },
  {
    title: "Find your peace in the comfort of your home",
    description:
      "At SUNLAND Communities, we understand that your home is more than just a place; it's your sanctuary, your haven, and the center of your life. It's where you create memories, find   solace, and build your dreams. Our mission is to help you discover the perfect space that resonates with your unique lifestyle and aspirations. Our team is always available to answer your questions, provide guidance, and ensure a smooth buying or selling process. We prioritize honesty, transparency, and ethical practices, ensuring your peace of mind throughout the real estate journey.",
    image: DreamToReality,
  },
];

export const maintenanceServiceData = [
  {
    image: AirConditioning,
    title: "Air Conditioning",
    description: "Request AC maintenance now.",
  },
  {
    image: Electrical,
    title: "Electrical",
    description: "Request Electrical maintenance now.",
  },
  {
    image: Plumbing,
    title: "Plumbing",
    description: "Request Plumbing maintenance now.",
  },
  {
    image: Elevator,
    title: "Elevator",
    description: "Request Elevator maintenance now.",
  },
  {
    image: Ceiling,
    title: "Ceiling",
    description: "Request Ceiling maintenance now.",
  },
  {
    image: Window,
    title: "Window Maintenance ",
    description: "Request Window maintenance now.",
  },
  {
    image: Others,
    title: "Others",
    description: "Report other problems or file a complaint.",
  },
  {
    image: WaterHeater,
    title: "Water Heater",
    description: "Request Water Heater maintenance now.",
  },
  {
    image: Floor,
    title: "Floor Maintenance ",
    description: "Request Floor maintenance now.",
  },
  {
    image: Door,
    title: "Door Maintenance",
    description: "Request Door maintenance now.",
  },
];

export const contactDetailsData = [
  {
    icon: <PhoneIcon />,
    type: "tel",
    title: "Call us",
    detailTitle: "Mansour Nosir",
    detail: "+971 509 080 595",
    subDetailTitle: "Carme Batac",
    subDetail: "+971 565 855 530",
  },
  {
    icon: <EmailIcon />,
    type: "mailto",
    title: "Email us",
    detail: "sunland.property.management@gmail.com",
    subDetail: "",
  },
  {
    icon: <AddressIcon />,
    link: "https://goo.gl/maps/rvDNpXRvviC5WPfJ8",
    title: "Address",
    detail: "Abu Dhabi, Rabdan Mall, 2nd floor, Office 205",
  },
];

// SMTP SETUP

export var Email = {
  send: function (a) {
    return new Promise(function (resolve, reject) {
      a.nocache = Math.floor(1e6 * Math.random() + 1);
      a.Action = "Send";
      var t = JSON.stringify(a);

      var xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://sunlandcommunities.ae/common_mail_php.php",
        true
      );
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.onload = function () {
        var response = xhr.responseText;
        resolve(response);
      };
      xhr.onerror = function () {
        reject(new Error("Failed to send email."));
      };
      xhr.send(t);
    });
  },
};

// HTML template generator
export const generateEmailBody = (formData) => {
  const emailBody = `
    <html>
      <body>
      <h1>New Query Received!</h1>
        <p>Below is the form data submitted by the user:</p>
        <ul>
          ${Object.entries(formData)
            .map(([key, value]) => `<li><strong>${key}:</strong> ${value}</li>`)
            .join("")}
        </ul>
      </body>
    </html>
  `;

  return emailBody;
};
