import { Fade } from "react-reveal";
import { Navbar } from "../Navbar";
import { Link, useLocation } from "react-router-dom";
import { AudioOff, AudioOn } from "../../assets";
import { useState } from "react";

export const HomeBanner = () => {
  const location = useLocation();
  const [audio, setAudio] = useState(true);
  return (
    <>
      <div className="banner-container">
        <Navbar />
        <div className="home-banner-wrapper">
          {/* <video muted={audio} autoPlay loop className="myVideo clipped-video">
            <source
              src="https://sunlandcommunities.ae/bg-video-hq.mp4"
              type="video/mp4"
            />
          </video> */}
          <img
            src="https://www.arabianbusiness.com/cloud/2023/07/24/Dubai-real-estate.jpg"
            alt="dubai"
            className="myVideo clipped-video"
          />
          <div
            className={
              location.pathname === "/"
                ? "overlay-screen clipped"
                : "overlay-screen"
            }
          >
            <div className="banner-text-wrapper">
              <Fade bottom>
                <h2 className="banner-text">
                  Unlocking Doors to Your Home: Your New Key in Abu Dhabi
                </h2>
              </Fade>
              <div className="button-group">
                <Fade left>
                  <Link to="/contact">
                    <button className="contact-button">Contact Us</button>
                  </Link>
                </Fade>
                <Fade right>
                  <Link to="/about">
                    <button className="learn-more-button">Learn More</button>
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
          {/* <div className="audio-controller">
            <p onClick={() => setAudio(!audio)}>
              {!audio ? <AudioOn /> : <AudioOff />}
            </p>
          </div> */}
      </div>
    </>
  );
};
