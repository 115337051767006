import { useState } from "react";
import { Fade } from "react-reveal";
import Select from "react-select";
import { Email, generateEmailBody } from "../../assets/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const enquiryOptions = [
  { value: "General", label: "General" },
  { value: "Renting", label: "Renting" },
  { value: "Maintenance", label: "Maintenance" },
];

export const ContactForm = () => {
  const [contactDetails, setContactDetails] = useState({});

  const handleFormDetailChange = (event, name) => {
    let fieldTitle = name || event.target.name;
    let fieldValue =
      event.target && event.target.value !== undefined
        ? event.target.value
        : event.value;

    setContactDetails((prevContactDetails) => ({
      ...prevContactDetails,
      [fieldTitle]: fieldValue,
    }));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      "&:focus": {
        outlineColor: "#b97d6d",
        borderColor: "#b97d6d",
      },
    }),
  };

  const submitQuery = (event) => {
    event.preventDefault();
    console.log(contactDetails, "contct");

    setContactDetails({});

    Email.send({
      Host: process.env.REACT_APP_SMTP_HOST,
      Username: process.env.REACT_APP_SMTP_HOST_USER,
      Password: process.env.REACT_APP_SMTP_HOST_PASS,
      To: contactDetails.email,
      From: process.env.REACT_APP_SMTP_HOST_USER,
      Subject: "WEBSITE ENQUIRY!",
      Body: "<h1>Thankyou!</h1>",
    }).then((message) => toast.success("Message Sent successfully!"));

    Email.send({
      Host: process.env.REACT_APP_SMTP_HOST,
      Username: process.env.REACT_APP_SMTP_HOST_USER,
      Password: process.env.REACT_APP_SMTP_HOST_PASS,
      From: contactDetails.email,
      To: process.env.REACT_APP_SMTP_HOST_USER,
      Subject: "WEBSITE ENQUIRY!",
      Body: generateEmailBody(contactDetails),
    });
  };

  return (
    <>
      <section className="contact-form-section">
        <div className="overlay-bg"></div>
        <div className="overlay-screen">
          <div className="content-wrapper">
            <Fade bottom>
              <div className="contact-section-left-pane">
                <div className="contact-section-title-container">
                  <p className="title">Get in touch with us</p>
                  <h4 className="sub-title">Let’s connect! </h4>
                </div>
                <p className="description">
                  We're here to make your real estate journey a memorable and
                  rewarding experience. Whether you have questions, inquiries,
                  or simply want to explore your options, our dedicated team is
                  ready to assist you.
                </p>
              </div>
            </Fade>
            <Fade bottom delay={1000}>
              <div className="contact-section-right-pane">
                <div className="contact-form-container">
                  <div className="enquiry-type-wrapper field-wrapper ">
                    <label htmlFor="enquiryType">Enquiry Type</label>
                    <Select
                      className="select-component"
                      defaultValue={contactDetails?.enquiryType || ""}
                      name="enquiryType"
                      onChange={(event) =>
                        handleFormDetailChange(event, "enquiryType")
                      }
                      placeholder="Select Enquiry Type"
                      options={enquiryOptions}
                      styles={customStyles}
                    />
                  </div>
                  <div className="field-wrapper">
                    <label>Your Information</label>
                    <input
                      name="iAm"
                      type="text"
                      placeholder="I'm a"
                      value={contactDetails.iAm || ""}
                      required
                      onChange={(event) => handleFormDetailChange(event)}
                    />
                    <div className="inline-fields-wrapper">
                      <input
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={contactDetails.firstName || ""}
                        onChange={(event) => handleFormDetailChange(event)}
                      />
                      <input
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={contactDetails.lastName || ""}
                        onChange={(event) => handleFormDetailChange(event)}
                      />
                    </div>
                    <input
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      value={contactDetails.email || ""}
                      onChange={(event) => handleFormDetailChange(event)}
                    />
                    <textarea
                      rows={4}
                      placeholder="Message"
                      name="message"
                      value={contactDetails.message || ""}
                      onChange={(event) =>
                        handleFormDetailChange(event, "message")
                      }
                    ></textarea>
                  </div>

                  <button className="form-submit-button" onClick={submitQuery}>
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};
